<template>
  <div class="textBox">
    <div class="header">免责说明</div>
    <div class="text">
      1.{{disclaimer[0]}}
      <span class="underline">{{disclaimer[1]}}</span>
    </div>
    <div class="text underline">2.{{disclaimer[2]}}</div>
    <div class="text underline">3.{{disclaimer[3]}}</div>
    <div class="text color">4.{{disclaimer[4]}}</div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      disclaimer: [
        "奈可狮尼所载文字图片等稿件出于为公众分享更多资讯信息之目的，皆由喜爱奈可狮尼的小资男们朋友圈、微信群等处收集整理而成，",
        "不代表本平台认同其观点或证实其内容，本平台不对其科学性、严肃性等作任何形式的保证。",
        "奈可狮尼所有信息仅供参考，不做交易和服务的数据，内容的真实性、准确性和合法性由原作者负责。",
        "任何单位或个人认为本平台内容（包括但不限于奈可狮尼用户发布的任何信息）可能涉嫌侵犯其合法权益，应该及时向我们提出书面权利通知，并提供权属证明、具体链接（URL）及详细侵权情况。在收到上述法律文件后，本平台将会依法尽快溢出相关涉嫌侵权的内容。",
        "本声明适用于奈可狮尼所发布（涵盖自动回复）中所有文章及内容。"
      ]
    };
  },
  components: {},
  created() {},
  methods: {}
};
</script>

<style scoped>
.textBox {
  width: 899px;
  padding-top: 35px;
}
.textBox .header {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
  height: 60px;
  line-height: 60px;
}

.text {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
  padding-bottom: 30px;
}

.underline {
    color:#333333;
    text-decoration: underline
}
.color {
 color:#333333;
}
</style>
