<template>
  <div class="personalBox flex j_s">
    <div class="left">
      <div @click="toShopCart" class="shopCar">我的购物车</div>
      <div class="typeBox" v-for="(item,i) in typeList" :key="i">
        <div
          @click="chosePersonalType(i)"
          class="type_chose"
          :class="{on:$store.state.personalIndex == i}"
        >{{item}}</div>
      </div>
    </div>
    <div class="right">
      <AllOrders v-show="$store.state.personalIndex==0"></AllOrders>
      <AddressList v-show="$store.state.personalIndex==1"></AddressList>
      <Disclaimer v-show="$store.state.personalIndex==4"></Disclaimer>
    </div>
  </div>
</template>

<script>
import AllOrders from "@/components/personal/allOrders";
import Disclaimer from "@/components/personal/disclaimer";
import AddressList from "@/components/personal/addressList";
export default {
  data() {
    return {
      typeList: ["全部订单", "我的地址", "常见问题", "我的规范", "免责说明"],
      typeIndex: 0
    };
  },
  created() {
    this.fixType();
  },
  computed: {},
  components: {
    AllOrders,
    Disclaimer,
    AddressList
  },
  methods: {
    //跳转到购物车
    toShopCart() {
      this.routerTo("shopCart");
    },
    // 切换tab
    chosePersonalType(i) {
      if (i == 2) {
        this.routerTo("help");
      } else if(i == 3){
        this.routerTo("specil");
      }else {
        this.$store.commit("savePersonalIndex", i);
      }
    },
    //固定type栏状态
    fixType() {
      this.$store.commit("saveTypeIndex", 9);
    },
    //二级路由跳转
    routerTo(value) {
      this.$router.push({ name: value });
    }
  }
};
</script>

<style scoped>
.personalBox {
  width: 1200px;
  margin: 0 auto;
}
.personalBox .left {
  width: 125px;
  padding-top: 37px;
}

.personalBox .left .shopCar {
  width: 125px;
  height: 58px;
  line-height: 58px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.typeBox {
  display: flex;
  justify-content: center;
}
.personalBox .left .type_chose {
  display: inline-block;
  height: 54px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  text-align: center;
  line-height: 54px;
  padding: 0 14px;
  margin: 0 auto;
  cursor: pointer;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.personalBox .left .on {
  color: #333333;
  border-bottom: 2px solid #333333;
}

.personalBox .right {
  width: 1060px;
  padding: 20px 0;
  min-height: 800px;
}
</style>

