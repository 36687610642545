<template>
  <div class="ordersBox noSel">
    <div class="header">
      <div class="header_typ flex j_s a_c">
        <div @click="changeType(1)" :class="{on:choseOrderType == 1}">全部订单({{not_allnum}})</div>
        <div @click="changeType(2)" :class="{on:choseOrderType == 2}">待付款({{not_paynum}})</div>
        <div @click="changeType(3)" :class="{on:choseOrderType == 3}">待发货({{not_delivernum}})</div>
        <div @click="changeType(4)" :class="{on:choseOrderType == 4}">待收货({{not_receiptnum}})</div>
      </div>
      <!-- <div class="search">
        <div class="search_box flex a_c j_s">
          <input type="text" v-model="seachShopBuy" placeholder="订单搜索" />
          <div class="search_icon flex a_c j_c">
            <img src="https://static.5t1800.com/s2/2021/06/10/60c1af2976e0e.png" alt />
          </div>
        </div>
      </div>-->
    </div>

    <div v-loading="loading" class="ordersBox_list">
      <ul class="option flex a_c j_s">
        <li class="time">订单</li>
        <li style="width:155px;"></li>
        <li style="width:110px;">单价</li>
        <li style="width:100px;">数量</li>
        <li style="width:60px;"></li>
        <li style="width:180px;">支付宝总金额</li>
        <li style="width:110px;">订单状态</li>
        <li style="width:110px;">操作</li>
      </ul>
      <div style="padding:20px" v-show="this.orderList.length == 0 && !loading">暂无相关订单!</div>
      <div class="shopList" v-for="(item,i) in orderList" :key="i">
        <div class="shopList_header flex j_s a_c">
          <div>
            下单时间：2021-06-10
            <span style="padding-left:76px;">奈可狮尼商城</span>
          </div>
          <div></div>
        </div>
        <ul class="shopList_list flex j_s" v-for="(items,i2) in item.items" :key="i2">
          <img style="width:140px;height:140px;" :src="items.goods_img" alt />
          <li class="shopList_list_text">
            <div>{{items.goods_title}}</div>
            <div>{{items.sku_title}}</div>
          </li>
          <li style="width:110px;">￥{{items.price}}</li>
          <li style="width:100px;">{{items.number}}</li>
          <li style="width:60px;">
            <!-- <span v-if="i2 == 0">{{item.consignee}}</span> -->
          </li>
          <li style="width:180px;">
            <div v-if="i2 == 0">
              <div>￥{{item.price}}</div>
              <div style="font-size:12px;color:#999999;line-height:22px;">含运费（0.00）</div>
              <div style="font-size:12px;color:#999999;line-height:20px;">支付宝支付</div>
            </div>
          </li>
          <li style="width:110px;">
            <div v-if="i2 == 0">
              <div>{{item.status_text}}</div>
              <div
                @click="toOrderDetails(i)"
                style="font-size:12px;color:#666666;line-height:22px;cursor:pointer"
              >查看详情</div>
              <div
                @click="toOrderDetails(i)"
                style="font-size:12px;color:#FF2742;line-height:22px;cursor:pointer"
              >查看物流</div>
            </div>
          </li>
          <li style="width:110px;">
            <div
              v-if="i2 == 0 && choseOrderType == 4"
              style="margin-bottom:10px;cursor:pointer;font-size:16px;"
              @click="receipt(i)"
            >确认收货</div>
            <div
              v-if="i2 == 0 && choseOrderType == 2"
              style="margin-bottom:10px;cursor:pointer;font-size:16px;"
              @click="removeOrder(i)"
            >取消订单</div>
            <span v-if="i2 == 0" style="cursor:pointer" @click="addAddress(i)">加入购物车</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="paging flex a_c j_r">
      <el-pagination
        :hide-on-single-page="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="5"
        layout="prev, pager, next, jumper"
        :total="showListLen"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "",
  props: {},
  data() {
    return {
      seachShopBuy: "",
      choseOrderType: 1,
      currentPage: 0,
      showListLen: 0,
      orderList: [],
      num_status: [],
      not_allnum: 0,
      not_paynum: 0,
      not_delivernum: 0,
      not_receiptnum: 0,
      loading: false
    };
  },
  components: {},
  created() {
    this.showListLen = this.orderList.length;
    this.getOrderList(this.choseOrderType);
    this.getNotNum();
  },
  methods: {
    //取消订单
    removeOrder(i) {
      this.loading = true;
      var params = {
        order_id: this.orderList[i].order_id
      };
      api
        .$cancel(params)
        .then(res => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.loading = false;
            this.$message({
              message: "取消成功",
              type: "success",
              duration: 1000
            });
            this.getOrderList(2);
            this.getNotNum();
          } else {
            this.loading = false;
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    //确认收货
    receipt(i) {
      this.loading = true;
      var params = {
        order_id: this.orderList[i].order_id
      };
      api
        .$receipt(params)
        .then(res => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.loading = false;
            this.$message({
              message: "收货成功",
              type: "success",
              duration: 1000
            });
            this.getOrderList(4);
          } else {
            this.loading = false;
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    //加入购物车
    addAddress(i) {
      var shop = this.orderList[i];
      console.log(shop);
      var list = shop.items;
      list.forEach(item => {
        var shop = {},
          goods_params = [];
        goods_params.push({
          id: item.goodsid,
          number: item.number,
          skuid: item.skuid
        });
        shop.img_path = item.goods_img;
        shop.skuTitle = item.sku_title;
        shop.title = item.goods_title;
        shop.price = item.price;
        shop.number = item.number;
        shop.skuTitle = item.sku_title;
        shop.skuid = item.skuid;
        shop.goods_params = goods_params;
        console.log(shop);
        this.WYfun.getShopCartSto(shop);
      });
      this.$message({
        message: "添加成功",
        type: "success",
        duration: 1500,
        onClose: () => {
          window.location.reload();
        }
      });
    },
    //删除订单
    delOrder(i) {
      console.log(i);
    },
    //切换选项
    changeType(value) {
      this.choseOrderType = value;
      this.getOrderList(value);
      switch (value) {
        case 1:
          this.showListLen = this.not_allnum;
          break;
        case 2:
          this.showListLen = this.not_paynum;
          break;
        case 3:
          this.showListLen = this.not_delivernum;
          break;
        default:
          this.showListLen = this.not_receiptnum;
      }
    },
    //跳转至详情
    toOrderDetails(i) {
      var order_id = this.orderList[i].order_id;
      this.$router.push({ path: `/details/${order_id}` });
    },
    //获取用户订单列表
    getOrderList(value, page = 1) {
      this.loading = true;
      var params = {
        status: value,
        page: page,
        limit: 5
      };
      api
        .$getOrderList(params)
        .then(res => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.loading = false;
            this.orderList = res.data.data.list;
            this.not_allnum = res.data.data.count;
            if (value == 1) {
              this.showListLen = res.data.data.count;
            }
          } else {
            this.loading = false;
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    //获取其他状态订单数量
    getNotNum() {
      api
        .$getOrderNum()
        .then(res => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.not_paynum = res.data.data.not_pay.num;
            this.not_delivernum = res.data.data.not_deliver.num;
            this.not_receiptnum = res.data.data.not_receipt.num;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //二级路由跳转
    routerTo(value) {
      this.$router.push({ name: value });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getOrderList(this.choseOrderType, val);
    }
  }
};
</script>

<style scoped>
.ordersBox {
  box-sizing: border-box;
  border: 1px solid #999999;
  padding: 20px 20px 0 20px;
}
.ordersBox .header {
  border: 1px solid #dcdcdc;
  margin-bottom: 20px;
}
.header_typ {
  height: 60px;
  /* border-bottom: 1px solid #dcdcdc; */
}
.search {
  padding: 10px;
}

.search_box {
  width: 280px;
  height: 40px;
  border: 1px solid #cdcdcd;
}
.search_box input {
  outline: none;
  border: none;
  text-indent: 1em;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.search_icon {
  width: 62px;
  height: 40px;
  background: #dcdcdc;
  cursor: pointer;
}
.search_icon img {
  width: 24px;
  height: 24px;
}
.ordersBox .header_typ > div {
  height: 36px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 36px;
  border-right: 1px solid #cdcdcd;
  text-align: center;
  flex: 1;
  cursor: pointer;
}
.ordersBox_list {
  border: 1px solid #dcdcdc;
}
.ordersBox .header_typ > div:last-child {
  border: none;
}
.ordersBox .header_typ > div img {
  width: 23px;
  height: 23px;
  margin-left: 10px;
}
.ordersBox .header_typ .on {
  color: #333333;
}

.option {
  height: 57px;
  background: #dcdcdc;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding-left: 58px;
}
.option li {
  text-align: center;
}
.shopList {
  border-bottom: 1px solid #cdcdcd;
  padding-left: 58px;
}

.shopList:last-child {
  border: none;
}

.option .time {
  width: 140px;
}

.shopList_header {
  height: 42px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 42px;
  padding-right: 10px;
}
.shopList_header img {
  width: 13px;
  height: 14px;
  cursor: pointer;
}
.shopList_list {
  margin-bottom: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
.shopList_list > li {
  padding-top: 20px;
}

.shopList_list_text {
  box-sizing: border-box;
  width: 155px;
  padding-top: 20px;
  text-align: left;
  padding-left: 10px;
}

.shopList_list_text div {
  width: 155px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.paging {
  height: 78px;
}
/deep/.paging .number {
  color: #c1c1c1 !important;
}
/deep/.paging .active {
  color: #000000 !important;
}
/deep/.paging .el-input .el-input__inner {
  border-color: #dcdfe6 !important;
}
</style>
