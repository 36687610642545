<template>
  <div class="settleBox noSel">
    <div class="receive">
      <div class="headerBox">收货信息</div>
      <div class="info">
        <div class="inputBox">
          <div>
            <input
              style="width:240px;"
              type="text"
              v-model="addressInfo.contact"
              placeholder="请输入收货人姓名"
              autocomplete="off"
            />
            <input
              style="width:364px;margin-left:20px;"
              type="text"
              v-model="addressInfo.mobile"
              placeholder="请输入联系方式"
              autocomplete="off"
            />
          </div>
          <div class="input_address">
            <el-select @change="choseProvince" v-model="provinceName" placeholder="请选择">
              <el-option
                v-for="item in provinceList"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <span></span>
            <el-select @change="choseCity" v-model="cityName" placeholder="请选择">
              <el-option
                v-for="item in cityList"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <span></span>
            <el-select @change="choseArea" v-model="areaName" placeholder="请选择">
              <el-option
                v-for="item in areaList"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div>
            <input
              style="width:760px;"
              type="text"
              v-model="addressInfo.address"
              placeholder="请输入详细地址信息"
              autocomplete="off"
              name
              id
            />
          </div>
        </div>
        <div class="default flex a_c">
          <div @click="changeIsDefault" class="icon">
            <img
              v-show="addressInfo.isdefault == 1"
              src="https://static.5t1800.com/s2/2021/06/17/60caab1b628dc.png"
              alt
            />
          </div>
          <span>设置为默认地址</span>
        </div>
        <div @click="setAddress" v-loading="addLoading" class="preserve">保存</div>
      </div>
    </div>
    <div class="addListBox" v-loading="loading" style="margin-top:30px">
      <ul class="flex">
        <li style="width:150px;">收货人</li>
        <li style="width:150px;">所在地区</li>
        <li style="width:300px;">详细地址</li>
        <li style="width:150px;">电话/手机</li>
        <li style="width:150px;">操作</li>
        <li style="width:150px;"></li>
      </ul>
      <div class="tableBox_auto" @scroll="scrollEvent">
        <div class="tableBox flex" v-for="(item,i) in addList" :key="i">
          <li style="width:150px;">{{item.contact}}</li>
          <li style="width:150px;">{{item.province_name}}</li>
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.province_name + item.city_name + item.area_name + item.address"
            placement="top"
          >
            <li
              class="address"
            >{{item.province_name}}{{item.city_name}}{{item.area_name}}{{item.address}}</li>
          </el-tooltip>
          <li style="width:150px;">{{item.mobile}}</li>
          <li style="width:150px;">
            <span @click="changeAdd(i)" style="cursor:pointer;margin-right:5px">修改</span>|
            <span @click="delAdd(i)" style="cursor:pointer;margin-left:2px">删除</span>
          </li>
          <li style="width:150px;cursor:pointer" class="flex a_c j_c">
            <span v-if="item.isdefault == 0" @click="setDefault(i)">设为默认</span>
            <span v-if="item.isdefault == 1" @click="setDefault(i)" class="default">默认地址</span>
          </li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    return {
      provinceName: "",
      cityName: "",
      areaName: "",
      provinceId: "",
      cityId: "",
      areaId: "",
      provinceList: [],
      cityList: [],
      areaList: [],
      addressInfo: {
        contact: "",
        mobile: "",
        province: "",
        city: "",
        area: "",
        address: "",
        isdefault: 0,
        id:''
      },
      addList: [],
      addressNum: 1,
      addressAdd: true,
      loading: false,
      addLoading: false,
      defaultLoading: false
    };
  },
  created() {
    this.getProvince();
    this.getUserAddList(this.addressNum);
  },
  computed: {},
  components: {},
  methods: {
    //保存地址
    setAddress() {
      console.log(this.addressInfo);

      if (
        !this.addressInfo.contact ||
        !this.addressInfo.mobile ||
        !this.addressInfo.province ||
        !this.addressInfo.city ||
        !this.addressInfo.area ||
        !this.addressInfo.address
      ) {
        this.$message({
          message: "请完善收货信息！",
          type: "error"
        });
      } else if (!this.loading) {
        this.addLoading = true;
        api
          .$addRessChange(this.addressInfo)
          .then(res => {
            console.log(res.data);
            if (res.data.code == 0) {
              this.addLoading = false;
              this.$message({
                message: "添加成功",
                type: "success",
                duration: 1000
              });
              this.addressInfo = {
                contact: "",
                mobile: "",
                province: "",
                city: "",
                area: "",
                address: "",
                isdefault: 0,
                id:''
              };
              this.provinceName = "";
              this.cityName = "";
              this.areaName = "";
              this.addList = [];
              this.getUserAddList(1);
            } else {
              this.addLoading = false;
              this.$message({
                message: res.data.msg,
                type: "error",
                duration: 1000
              });
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    //编辑地址
    changeAdd(i) {
      console.log(i);
      this.addressInfo = {
        contact: this.addList[i].contact,
        mobile: this.addList[i].mobile,
        province: this.addList[i].province,
        city: this.addList[i].city,
        area: this.addList[i].area,
        address: this.addList[i].address,
        isdefault: this.addList[i].isdefault,
        id:this.addList[i].id
      };
      this.provinceName = this.addList[i].province_name;
      this.cityName = this.addList[i].city_name;
      this.areaName = this.addList[i].area_name;

    },
    //删除地址
    delAdd(i) {
      this.loading = true;
      console.log(this.addList[i].id);
      var params = {
        id: this.addList[i].id
      };
      api
        .$delAddress(params)
        .then(res => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.loading = false;
            this.$message({
              message: "删除成功",
              type: "success",
              duration: 1000
            });
            this.addList = [];
            this.getUserAddList(1);
          } else {
            this.loading = false;
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 1000
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //设置为默认地址
    setDefault(i) {
      this.Loading = true;
      var params = {
        id: this.addList[i].id
      };
      api
        .$setDefault(params)
        .then(res => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.Loading = false;
            this.addList = [];
            this.getUserAddList(1);
          } else {
            this.Loading = false;
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 1000
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //选择是否默认
    changeIsDefault() {
      if (this.addressInfo.isdefault == 0) {
        this.addressInfo.isdefault = 1;
      } else {
        this.addressInfo.isdefault = 0;
      }
    },
    //滚动条触发事件
    scrollEvent(e) {
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight ==
        e.srcElement.scrollHeight
      ) {
        if (this.addressAdd) {
          this.addressNum++;
          this.getUserAddList(this.addressNum);
        }
      }
    },
    //选择省份
    choseProvince(value) {
      this.getCity(value);
      this.addressInfo.province = value;
      this.cityName = "";
      this.areaName = "";
      this.cityList = [];
      this.areaList = [];
    },
    //选择市
    choseCity(value) {
      this.getArea(value);
      this.addressInfo.city = value;
      this.areaName = "";
      this.areaList = [];
    },
    //选择区
    choseArea(value) {
      this.addressInfo.area = value;
      console.log(this.addressInfo);
    },
    //获取省列表
    getProvince() {
      api
        .$getChina()
        .then(res => {
          console.log(res.data);
          this.provinceList = res.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //获取市列表
    getCity(value) {
      var params = {
        province_id: value
      };
      api
        .$getChina(params)
        .then(res => {
          console.log(res.data);
          this.cityList = res.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //获取区列表
    getArea(value) {
      var params = {
        city_id: value
      };
      api
        .$getChina(params)
        .then(res => {
          console.log(res.data);
          this.areaList = res.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //获取用户地址列表
    getUserAddList(value) {
      this.loading = true;
      var params = {
        page: value
      };
      api
        .$getAddList(params)
        .then(res => {
          console.log(res.data);
          var addList = this.addList;
          this.addList = addList.concat(res.data.data.list);
          this.loading = false;
          if (res.data.data.list.length == 0) {
            this.addressAdd = false;
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.settleBox {
  width: 1060px;
  margin: 0 auto;
  padding-top: 20px;
}
.receive {
  border: 1px solid #999999;
}
.headerBox {
  height: 80px;
  background: #dcdcdc;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 80px;
  padding-left: 10px;
}
.info {
  padding: 10px;
}
.choseAddIcon {
  width: 106px;
  height: 27px;
  background: #000000;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 27px;
  cursor: pointer;
}
.inputBox input,
textarea {
  height: 46px;
  border: 1px solid #dcdcdc;
  outline: none;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-indent: 1em;
  margin: 10px 0;
}
.inputBox input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #ccc;
}
.inputBox input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ccc;
}
.inputBox input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ccc;
}
.inputBox input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #ccc;
}
.input_address span {
  width: 20px;
  display: inline-block;
}
/deep/.inputBox .el-input__inner {
  width: 246px;
  height: 50px;
  border-radius: 0;
  border: 1px solid #dcdcdc;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
/deep/.el-select-dropdown__item.selected {
  color: #000000;
  font-weight: 700;
}
/deep/.inputBox .el-select .el-input.is-focus .el-input__inner {
  border-color: #dcdcdc;
}
/deep/.info .el-radio__input.is-checked .el-radio__inner {
  border-color: #000000;
  background: #000000;
}
/deep/.info .el-radio__input.is-checked + .el-radio__label {
  color: #000000;
}
/deep/.info .el-radio__inner:hover {
  border-color: #000000;
}

.default {
  height: 31px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  padding-left: 10px;
}
.default .icon {
  width: 10px;
  height: 10px;
  border: 1px solid #cdcdcd;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
}
.default .icon img {
  width: 10px;
  height: 10px;
}
.preserve {
  width: 100px;
  height: 36px;
  background: #000000;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  margin: 10px 0;
}
.addListBox {
  box-sizing: border-box;
  width: 1060px;
  border-top: 1px solid #999999;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
  margin-bottom: 50px;
}
.addListBox ul {
  border-left: 1px solid #999999;
  border-right: 1px solid #999999;
  border-bottom: 1px solid #999999;
  background: rgb(233, 232, 232);
}
.addListBox li {
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
  border-right: 1px solid #999999;
}
.addListBox li:last-child {
  border-right: none;
}

.tableBox {
  box-sizing: border-box;
  min-height: 50px;
  border-bottom: 1px solid #999999;
  border-left: 1px solid #999999;
  border-right: 1px solid #999999;
}

.tableBox:first-child {
  border-top: none;
}
.tableBox .address {
  box-sizing: border-box;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 50px;
  cursor: pointer;
}
.tableBox .default {
  display: inline-block;
  width: 76px;
  height: 26px;
  background: rgba(255, 39, 66, 0.4);
  border: 1px solid #ff2742;
  line-height: 26px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  padding: 0;
  cursor: pointer;
}
.tableBox_auto {
  height: 500px;
  overflow-x: scroll;
  white-space: nowrap;
}

::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>

